var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fullstars > 0
    ? _c(
        "div",
        {
          staticClass: "ratings",
          attrs: { title: _vm.counttitle, "data-toggle": "tooltip" }
        },
        [
          _vm._l(_vm.fullstars, function(n, index) {
            return _c("i", { staticClass: "fa fa-star" })
          }),
          _vm._v(" "),
          _vm.halfstar
            ? _c("i", { staticClass: "fa fa-star-half-alt" })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.emptystars, function(n, index) {
            return _c("i", { staticClass: "fa fa-star-o" })
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }